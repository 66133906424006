import { Request } from 'src/app/models/request-message';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalPagerService } from './local-pager.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ManageOwnerService {
  baseURL = environment.apiUrl;

  constructor(private http: HttpClient, private pagerService: LocalPagerService) { }

  /* addOwner(Owner, fileToUpload: File) {
    if (Owner.parentCommunityOwnerId) {
      Owner.parentCommunityOwnerId = Owner.parentCommunityOwnerId.communityOwnerId;
    }
    return this.http.post<Request>(this.baseURL + 'ManageOwner/AddOwner', Owner);
  } */

Rejectreq(RegistrationRequestId)
{
    return this.http.get<any>(this.baseURL + 'ManageOwner/RejectOwnerRegRequest?RegistrationRequestId='+ RegistrationRequestId);  
}

  GetOwnerUnits(ownerId)
  {
    if (ownerId) {
      return this.http.get<any>(this.baseURL + 'ManageOwner/GetUnitsForOwner?ownerId=' + ownerId);
    } else {
      return this.http.get<any>(this.baseURL + 'ManageOwner/GetUnitsForOwner');
    }
  }

  addOwner(Owner, fileToUpload: File,fileid:File,Units:any[]) {
    debugger;
    const formData: FormData = new FormData();
    const formData2:FormData =new FormData();

    if (fileid) {
      formData.append('Fileid',fileid,fileid.name);
    }
    if (fileToUpload) {
      formData.append('file', fileToUpload, fileToUpload.name);
    }
    if (Owner.parentCommunityOwnerId) {
      Owner.parentCommunityOwnerId = Owner.parentCommunityOwnerId.communityOwnerId;
    }
    formData.append('owner', JSON.stringify(Owner));
    formData.append('Units',JSON.stringify(Units))
    return this.http.post<Request>(this.baseURL + 'ManageOwner/AddOwner', formData);
  }
  
  addRegRequest(Owner,email,ownertype, fileToUpload: File,fileid:File,Units:any[],Password,Salt,IDNumberPhotoPath) {
    this.debugger;
    const formData: FormData = new FormData();
    
    if (fileid) {
      formData.append('Fileid',fileid,fileid.name);
    }
    if (fileToUpload) {
      formData.append('file', fileToUpload, fileToUpload.name);
    }
    if (Owner.parentCommunityOwnerId) {
      Owner.parentCommunityOwnerId = Owner.parentCommunityOwnerId.communityOwnerId;
    }
    formData.append('owner', JSON.stringify(Owner));
    formData.append('Units',JSON.stringify(Units))
    formData.append('email', JSON.stringify(email));
    formData.append('ownertype', JSON.stringify(ownertype));
   
     formData.append('Password', JSON.stringify(Password));
    formData.append('Salt',JSON.stringify(Salt))
    formData.append('IDNumberPhotoPath',JSON.stringify(IDNumberPhotoPath))
    return this.http.post<Request>(this.baseURL + 'ManageOwner/AddOwnefromRegReq', formData);
  }  


  updateOwner(Owner, fileToUpload: File,fileid:File,Idphoto,photo) {
    const formData: FormData = new FormData();
    if (fileToUpload) {
      formData.append('file', fileToUpload, fileToUpload.name);
    }
    if (fileid) {
      formData.append('Fileid',fileid,fileid.name);
    }
    formData.append('Idphoto',JSON.stringify(Idphoto));
    formData.append('photo',JSON.stringify(photo));

    formData.append('owner', JSON.stringify(Owner));
    return this.http.post<Request>(this.baseURL + 'ManageOwner/UpdateOwner', formData);
  }

  deleteOwner(ownerId) {
    return this.http.get<any>(this.baseURL + 'ManageOwner/DeleteOwner?ownerId=' + ownerId);
  }
  debugger;
  getOwnerParents(RequestId){
   
      return this.http.get<any>(this.baseURL + 'ManageOwner/GetOwnerParents?RequestId='+RequestId);
  }
  getUnits(UnitNumber) {
    if (UnitNumber) {
      return this.http.get<any>(this.baseURL + 'ManageOwner/GetUnits?UnitNumber=' + UnitNumber);
    } else {
      return this.http.get<any>(this.baseURL + 'ManageOwner/GetUnits');
    }
  }

  getUnitForRegReq(RegstrationRequestId) {
    if (RegstrationRequestId) {
      return this.http.get<any>(this.baseURL + 'ManageOwner/GetUnitsForReGReq?RegstrationRequestId=' + RegstrationRequestId);
    } else {
      return this.http.get<any>(this.baseURL + 'ManageOwner/GetUnitsForReGReq');
    }
  }
  getaccompanes(RegstrationRequestId) {
    if (RegstrationRequestId) {
      return this.http.get<any>(this.baseURL + 'ManageOwner/GetAccompanies?RegstrationRequestId=' + RegstrationRequestId);
    } else {
      return this.http.get<any>(this.baseURL + 'ManageOwner/GetAccompanies');
    }
  }
  getOwner(RegstrationReqId) {
    this.debugger;
    return this.http.get<any>(this.baseURL + 'ManageOwner/GetRegstrationRequest?RegstrationReqId=' + RegstrationReqId);
  }
  getownerById(ownerId)
  {
    return this.http.get<any>(this.baseURL + 'ManageOwner/GetOwner?ownerId=' + ownerId);
       
  }
  sendVerifyCode(ownerId)
  {
    
    return this.http.get<any>(this.baseURL + 'ManageOwner/ResendVerification?ownerId=' + ownerId);
       
  }
  ResetPassword(ownerId)
  {
    
    return this.http.get<any>(this.baseURL + 'ManageOwner/ResetPassword?ownerId=' + ownerId);
       
  }
  addOwnerUnit(OwnerUnit) {
    return this.http.post<Request>(this.baseURL + 'ManageOwner/AddOwnerUnit', OwnerUnit);
  }

  updateOwnerUnit(OwnerUnit) {
    return this.http.post<Request>(this.baseURL + 'ManageOwner/UpdateOwnerUnit', OwnerUnit);
  }
   
  addownerunit(Unitnumber,OwnerId,ownerType,RentDateFrom,RentDateto)
  {
    this.debugger;
    const formData: FormData = new FormData();
    formData.append('Unitnumber', JSON.stringify(Unitnumber));
    formData.append('OwnerId',JSON.stringify(OwnerId))
    formData.append('ownerType',JSON.stringify(ownerType))
    formData.append('RentDateFrom',JSON.stringify(RentDateFrom))
    formData.append('RentDateto',JSON.stringify(RentDateto))
    return this.http.post<Request>(this.baseURL + 'ManageOwner/Addunit',formData );
  }
  deleteownerunit(UnitId)
   {
    return this.http.get<Request>(this.baseURL + 'ManageOwner/DeleteOwnerUnit?UnitId=' + UnitId);

   }
   getfeatcher(ownerId)
   {
    return this.http.get<any>(this.baseURL + 'ManageOwner/GetListOfFeatures?ownerId=' + ownerId);
   }
   getBlockfeatcher(ownerId)
   {
     this.debugger;
    return this.http.get<any>(this.baseURL + 'ManageOwner/GetListOfOwnerBlockFeatures?ownerId=' + ownerId);
   }
   Removefeatcher(ModuleFeatureId,ownerid)
   {
    const formData: FormData = new FormData();
    formData.append('ModuleFeatureId', JSON.stringify(ModuleFeatureId));
    formData.append('ownerid',JSON.stringify(ownerid))
    return this.http.post<Request>(this.baseURL + 'ManageOwner/RemoveFeature',formData );
   
   }
   ADDfeatcher(ModuleFeatureId:any[],ownerId)
   {
    const formData: FormData = new FormData();
    formData.append('ModuleFeatureId', JSON.stringify(ModuleFeatureId));
    formData.append('ownerid',JSON.stringify(ownerId))
    return this.http.post<Request>(this.baseURL + 'ManageOwner/AddFeature',formData );

   }

   SendEmailforRejectRequest(Email: string, Message:string) {
    return this.http.get<any>(this.baseURL + "ManageOwner/SendMailForRejectRequest?Email="+Email+"&Message="+Message)
}
getOwnerParentTotenant(AccountId){
   this.debugger;
  return this.http.get<any>(this.baseURL + 'ManageOwner/GetOwnerParentTotenant?AccountId='+AccountId);
}


}
